import '../css/main.css';
import '../css/Projects.css';

import {MainMenu} from "./MainMenu";

import antique_logo from "../images/antiquelogo.jpg"
import antimony_logo from "../images/download.png"
export function Projects() {
    return (
        <div className="App">
            <div className="App-center">
                <section className="SplitScreen">
                    <div id="antique-col">
                        <h2><img src={antique_logo} className="CommunityLogo" alt="logo"/></h2>
                        <h2>Antique Penguin Archival Project</h2>
                        <h6>A full archival of Club Penguin circa 2005-2008. If you're looking for
                        the original version of this classic game, give this version a try by clicking <a href="https://antiquepengu.in">here.</a></h6>
                        <h6>Note: Club Penguin is owned by the Walt Disney company and this distribution is available for educational/archival purposes only.
                        We respect intellectual property rights and if Disney ever requests this archival to be removed, it will be without notice.</h6>
                    </div>
                    <div id="autokrator-col">
                        <h2><img src={antimony_logo} className="CommunityLogo" alt="logo"/></h2>
                        <h2>Autokrator - An Eastern Roman Story</h2>
                        <h4>A historical fiction RPG based in the 5th century AD. Battle Vandals, Goths, and Persians to reconquer Rome
                        for the Autokrator of the Romans. Meet historical figures and get immersed in this world of Earthbound meets Gladiator.</h4>
                    </div>
                    <div id="acbg-col">
                        <h2><img src={antimony_logo} className="CommunityLogo" alt="logo"/></h2>
                        <h2>Untitled Animal Crossing Project</h2>
                        <h4>An online virtual world based on the Nintendo property Animal Crossing. Pay off your home loan and decorate your town,
                        while becoming closer and closer friends with the town's inhabitants. Play multiplayer with friends at the same time through wireless netplay.</h4>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default Projects();